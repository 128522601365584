import memoizeOne from 'memoize-one';

import { cfetch } from '@confluence/network';

interface InviteCapability {
	mode: string;
	permittedResources?: Array<string>;
	domains?: Array<string>;
}

interface InviteCapabilitiesEntry {
	// Site ARI: ari:cloud:confluence::site/<cloud_id>
	resourceARI: string;
	// e.g. "ari:cloud:confluence::role/product/member"
	role: string;
	directInvite: InviteCapability;
	invitePendingApproval: InviteCapability;
}

export interface InviteCapabilities {
	directInvite: string[];
	invitePendingApproval: string[];
}

export const DEFAULT_STARGATE_SERVICE_URL = '/gateway/api';
export const INVITE_CAPABILITIES = '/v3/invitations/capabilities';

function handleCapabilitiesResponse(response: InviteCapabilitiesEntry[]): InviteCapabilities {
	const directInvite = new Set<string>();
	const invitePendingApproval = new Set<string>();

	if (Array.isArray(response)) {
		response.forEach((entry) => {
			if (!directInvite.has(entry.resourceARI) && 'NONE' !== entry.directInvite.mode) {
				directInvite.add(entry.resourceARI);
			}

			if (
				!invitePendingApproval.has(entry.resourceARI) &&
				'NONE' !== entry.invitePendingApproval.mode
			) {
				invitePendingApproval.add(entry.resourceARI);
			}
		});
	}

	return {
		directInvite: [...directInvite],
		invitePendingApproval: [...invitePendingApproval],
	};
}

// This closely follows the implementation of the client from the Invite People package
// platform/packages/people-and-teams/invite-people/src/clients/InviteApiClient.ts
// This logic is subject to change at any time and should only be used for experimental purposes
const getInvitePermissions = async (cloudId: string): Promise<InviteCapabilities> => {
	const resource = `ari:cloud:platform::site/${cloudId}`;
	try {
		const response = await cfetch(
			`${DEFAULT_STARGATE_SERVICE_URL}${INVITE_CAPABILITIES}?resource=${resource}`,
		);
		const inviteResponse = await response.json();
		return handleCapabilitiesResponse(inviteResponse);
	} catch (error: any) {
		return error;
	}
};

// Please do not use this without reaching out to #cc-onboarding. This will be deprecated in the near future.
export const memoizedGetInvitePermissions = memoizeOne(getInvitePermissions);
