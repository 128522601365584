import { type EffectCallback, useEffect } from 'react';

/**
 * Runs an effect only on component mount.
 * Useful for effects we don't want to rerun even if their dependencies change, e.g., analytics events that fire on screen load.
 */
export const useMountEffect = (effect: EffectCallback) => {
	useEffect(() => {
		return effect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
