import { useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';

// TODO: replace with useLazyQuery which returns a promise when apollo is upgraded to v3
//todo: this needs to handle or pass the error
export const useImperativeQuery = <TQuery, TVars>(query) => {
	const { refetch } = useQuery<TQuery, TVars>(query, { skip: true });

	const imperativelyCallQuery = useCallback(
		(variables: TVars) => {
			return refetch(variables);
		},
		[refetch],
	);

	return imperativelyCallQuery;
};
