const INFI_SPOTLIGHT_KEY = 'is-infi-spotlight-seen';

const setInfiSpotlightValue = (value: string) => {
	try {
		localStorage.setItem(INFI_SPOTLIGHT_KEY, value);
	} catch (error) {
		// do nothing as we'll just mark them as having seen it
	}
};

const getOriginalInfiSpotlightValue = () => {
	const defaultValue = 'false';
	try {
		const storedValue = localStorage.getItem(INFI_SPOTLIGHT_KEY);
		return storedValue || defaultValue;
	} catch (error) {
		return defaultValue;
	}
};

// runs once on initial script load and stores value
const wasInfiSpotlightOriginallySeen = getOriginalInfiSpotlightValue();

export const suppressInfiSpotlight = () => {
	setInfiSpotlightValue('true');
};

export const resetInfiSpotlightValue = () => {
	if (wasInfiSpotlightOriginallySeen === 'false') {
		setInfiSpotlightValue('false');
	}
};
