import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	flagErrorTitle: {
		id: 'page-tree.dragndrop.failed.title',
		defaultMessage: 'Something went wrong',
	},
	flagErrorDescription: {
		id: 'page-tree.dragndrop.failed.description',
		defaultMessage:
			'Refresh the page and try again. If this keeps happening, contact your space administrator.',
		description:
			'A generic error message that displays in a flag to let the user know that there was a problem while loading their tree',
	},
	paginateErrorDescription: {
		id: 'page-tree.paginate-content.failed.description',
		defaultMessage:
			'We encountered a network error while loading more content. Refresh the page and try again. If this keeps happening, contact your space administrator.',
		description:
			'An error message that displays in a flag to let the user know that there was a problem while loading more content into their tree',
	},
	flagMovePagePermissionErrorTitle: {
		id: 'page-tree.dragndrop.permissions.error.title',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "You can't move this content",
		description:
			"Title of error message shown when a content can't be moved due to a permission error",
	},
	flagMovePagePermissionErrorDescription: {
		id: 'page-tree.dragndrop.permissions.error.decription',
		defaultMessage:
			// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
			// eslint-disable-next-line no-restricted-syntax
			"You don't have permission to move this restricted content. Contact your space administrator.",
		description:
			"Description of error message shown when a content can't be moved due to a permission error",
	},
	flagUndoTitle: {
		id: 'page-tree.dragndrop.undo.title',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: 'Moved "{pageTitle}"',
		description: 'The page is moved on the page tree',
	},
	flagUndoUntitled: {
		id: 'page-tree.dragndrop.undo.untitled',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: 'Moved "Untitled"',
		description: 'The untitled page is moved on the page tree',
	},
	flagUndoAction: {
		id: 'page-tree.dragndrop.undo.action',
		defaultMessage: 'Undo',
		description: 'Action to revert drag&drop movement on the page tree',
	},
	flagLargePageTreeTitle: {
		id: 'page-tree.dragndrop.lpt.title',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "We can't move this content",
		description: 'Message when there are too many contents in the content tree',
	},
	flagLargePageTreeDescription: {
		id: 'page-tree.dragndrop.lpt.description',
		defaultMessage:
			'Drag and drop is not available for a list of 500 or more. Refresh and try again without expanding the content tree.',
		description: 'Message when there are too many content in the content tree',
	},
	flagLargePageTreeSettingsAction: {
		id: 'page-tree.dragndrop.lpt.settings.action',
		defaultMessage: 'Go to space settings',
		description: 'Link to page Reorder Pages within Space settings',
	},
	flagLargePageTreeRefreshAction: {
		id: 'page-tree.dragndrop.lpt.refresh.action',
		defaultMessage: 'Refresh page',
		description: 'Link to refresh the page in order to try again',
	},
	flagInvalidMoveDraftTitle: {
		id: 'page-tree.dragndrop.invalid-move-draft.title',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "You can't move content under a draft",
		description: 'Title when the user is unable to move a content under a draft',
	},
	flagInvalidMoveDraftDescription: {
		id: 'page-tree.dragndrop.invalid-move-draft.description',
		defaultMessage: 'Publish the draft to move content here.',
		description:
			'Message when the user is unable to move a content under a draft and needs to publish it first',
	},
	untitledDraftText: {
		id: 'page-tree.untitled-draft',
		defaultMessage: 'Untitled',
		description: 'A placeholder for drafts that have no title',
	},
});
