import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

type Cohort = 'control' | 'sampleContentWithSpotlight' | 'sampleContentWithoutSpotlight';

export const useSampleContentExperiment = () => {
	const getCohort = (): Cohort => {
		const [expConfig] = UNSAFE_noExposureExp('cfind_create_sample_content_v2'); // Experiment exposure is fired in the backend
		const experimentVal = expConfig.get('cohort', 'control');
		return experimentVal as Cohort;
	};

	return {
		sampleContentCohort: getCohort(),
	};
};
