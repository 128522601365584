import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import type { ExecutionResult } from 'react-apollo';

import type { TreeDestinationPosition, TreeSourcePosition } from '@confluence/tree';

import type { ContentTreeItem } from './data-transformers';
import { MovePageAfterMutation as MovePageAfter } from './MovePageAfterMutation.graphql';
import { MovePageAppendMutation as MovePageAppend } from './MovePageAppendMutation.graphql';
import { MovePageBeforeMutation as MovePageBefore } from './MovePageBeforeMutation.graphql';
import { MovePageTopLevelMutation as MovePageTopLevel } from './MovePageTopLevelMutation.graphql';
import type {
	MovePageAfterMutation,
	MovePageAfterMutationVariables,
} from './__types__/MovePageAfterMutation';
import type {
	MovePageBeforeMutation,
	MovePageBeforeMutationVariables,
} from './__types__/MovePageBeforeMutation';
import type {
	MovePageAppendMutation,
	MovePageAppendMutationVariables,
} from './__types__/MovePageAppendMutation';
import type {
	MovePageTopLevelMutation,
	MovePageTopLevelMutationVariables,
} from './__types__/MovePageTopLevelMutation';

export type MovePageToNewParentParams = {
	pageId: string;
	parentId: string;
};

export type MovePageAlongsideSiblingParams = {
	pageId: string;
	siblingId: string;
	positionToSibling: 'before' | 'after';
};

export type MovePageTopLevelParams = {
	pageId: string;
	spaceKey: string;
};

export type MovePageParams =
	| MovePageToNewParentParams
	| MovePageAlongsideSiblingParams
	| MovePageTopLevelParams;

export type MovePageAdditionalParams = {
	contentStatus: string | null;
	destination: TreeDestinationPosition;
	movedPageTitle: string;
	source: TreeSourcePosition;
	sourceParent: ContentTreeItem;
	spaceId?: string | null;
	revertMove: () => MovePageParams;
};

export type OverrideMovePageParams = {
	newParentPageId?: string;
	newSpaceKey: string;
	targetId?: string;
	position?: string;
	closeMove?: () => void;
};

export type UnifiedMutationResult = {
	mutationResult: {
		page: {
			id: string | null;
			links: {
				webui: string | null;
				editui: string | null;
			} | null;
		} | null;
	} | null;
};

export type MovePageHandler = (
	params: MovePageParams,
) => Promise<ExecutionResult<UnifiedMutationResult>>;

export const useMovePageHandler = () => {
	const [movePageAfter] = useMutation<MovePageAfterMutation, MovePageAfterMutationVariables>(
		MovePageAfter,
	);
	const [movePageBefore] = useMutation<MovePageBeforeMutation, MovePageBeforeMutationVariables>(
		MovePageBefore,
	);
	const [movePageAppend] = useMutation<MovePageAppendMutation, MovePageAppendMutationVariables>(
		MovePageAppend,
	);
	const [movePageTopLevel] = useMutation<
		MovePageTopLevelMutation,
		MovePageTopLevelMutationVariables
	>(MovePageTopLevel);

	const movePage = useCallback<MovePageHandler>(
		async (params) => {
			const thenFn =
				<T>(resultFormatter: (data: T) => UnifiedMutationResult['mutationResult']) =>
				({ data, ...rest }: ExecutionResult<T>): ExecutionResult<UnifiedMutationResult> => {
					return {
						...rest,
						data:
							typeof data === 'undefined'
								? undefined
								: {
										mutationResult: resultFormatter(data),
									},
					};
				};

			if ('parentId' in params) {
				return movePageAppend({
					variables: { pageId: params.pageId, parentId: params.parentId },
				}).then(thenFn((data) => data.movePageAppend));
			} else if ('spaceKey' in params) {
				return movePageTopLevel({
					variables: { pageId: params.pageId, spaceKey: params.spaceKey },
				}).then(thenFn((data) => data.movePageTopLevel));
			} else if (params.positionToSibling === 'after') {
				return movePageAfter({
					variables: { pageId: params.pageId, siblingId: params.siblingId },
				}).then(thenFn((data) => data.movePageAfter));
			} else {
				return movePageBefore({
					variables: { pageId: params.pageId, siblingId: params.siblingId },
				}).then(thenFn((data) => data.movePageBefore));
			}
		},
		[movePageAfter, movePageAppend, movePageBefore, movePageTopLevel],
	);

	return { movePage };
};
