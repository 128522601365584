import produce from 'immer';
import without from 'lodash/without';

import {
	getEmojiFromPublishedPageProperties,
	getEmojiFromDraftPageProperties,
} from '@confluence/emoji-title/entry-points/transformer';
import { getTitleAndEmoji } from '@confluence/emoji-title/entry-points/helpers';
import { getEditorVersionFromPageProperties } from '@confluence/content-utils';
import type { ItemId, TreeObjectItems, TreeItem } from '@confluence/tree';

import {
	childrenAreLatest,
	getDirectionalCursors,
	getIds,
	getPages,
	getRootId,
	isPageTreeTruncatedPrevious,
	isPageTreeTruncatedFollowing,
	mergePages,
} from './data-extractors';
import { VIRTUAL_ROOT_ID, VIRTUAL_LOCAL_ROOT_ID } from './virtualRootIds';

// TODO: various types here can/should be narrowed by making createTreeItem more robust
export interface ContentTreeItem extends TreeItem {
	parentId?: ItemId | null;
	isChildrenLoading: boolean;
	hasRestrictions?: boolean;
	hasInheritedRestrictions?: boolean;
	data: {
		title?: string;
		type?: string;
		fullTitle?: string;
		status?: string;
		createdDate?: number;
		blank?: boolean;
		emoji?: string;
		emojiFromProperties?: string;
		webui?: string;
		editorVersion?: string;
		isSelected?: boolean;
		isHighlighted?: boolean;
		isUnread?: boolean;
	};
}

export const createTreeItem = (
	page,
	isShowingBlankDraftsEnabled,
	selectedPageId?,
	isWhiteboardEmojiFFEnabled = false,
): ContentTreeItem => {
	const {
		text: title,
		emoji: displayEmoji,
		selectedEmoji: emojiFromProperties,
	} = getTitleAndEmoji(
		page.title,
		getEmojiFromPublishedPageProperties(page),
		getEmojiFromDraftPageProperties(page),
		page.status,
		undefined,
		isWhiteboardEmojiFFEnabled || page.type !== 'whiteboard',
	);
	const editorVersion = getEditorVersionFromPageProperties(page);
	// editor should be v1, v2, or ""

	return {
		id: page.id,
		children: getPages(page.children, isShowingBlankDraftsEnabled, selectedPageId),
		parentId: null,
		hasChildren: page.hasChildren,
		isExpanded: false,
		isChildrenLoading: false,
		hasRestrictions: page.hasRestrictions || false,
		hasInheritedRestrictions: page.hasInheritedRestrictions || false,
		// Disallow dropping onto drafts
		blockedInstructions: page.status === 'CURRENT' ? undefined : ['make-child'],
		data: {
			title: title || '',
			fullTitle: page.title,
			status: page.status,
			blank: page.blank,
			emoji: displayEmoji,
			emojiFromProperties,
			webui: page.links?.webui,
			type: page.type,
			editorVersion,
			createdDate: parseInt(page.createdDate?.value, 10),
		},
	};
};

/**
 * Flattens the data retrieved relative to a currentPage in order to make the
 * tree renderable top-down.
 *
 * @param {object} currentPage
 * @param {object} dictionary
 */
export const flattenCurrentTree = (
	currentPage,
	dictionary = {},
	isShowingBlankDraftsEnabled,
	selectedPageId,
	isWhiteboardEmojiFFEnabled = false,
): TreeObjectItems<ContentTreeItem> | undefined => {
	if (!currentPage) {
		return;
	}

	const children = getPages(currentPage.children, isShowingBlankDraftsEnabled, selectedPageId);
	const ancestors = getPages(
		currentPage.nearestAncestors,
		isShowingBlankDraftsEnabled,
		selectedPageId,
	);
	const siblings = [
		...getPages(currentPage.previousSiblings, isShowingBlankDraftsEnabled, selectedPageId),
		...getPages(currentPage.followingSiblings, isShowingBlankDraftsEnabled, selectedPageId),
	];
	dictionary[currentPage.id] = createTreeItem(
		currentPage,
		isShowingBlankDraftsEnabled,
		selectedPageId,
		isWhiteboardEmojiFFEnabled,
	);
	siblings.forEach((sibling) =>
		flattenCurrentTree(
			sibling,
			dictionary,
			isShowingBlankDraftsEnabled,
			selectedPageId,
			isWhiteboardEmojiFFEnabled,
		),
	);
	ancestors.forEach((ancestor) =>
		flattenCurrentTree(
			ancestor,
			dictionary,
			isShowingBlankDraftsEnabled,
			selectedPageId,
			isWhiteboardEmojiFFEnabled,
		),
	);
	children.forEach((child) =>
		flattenCurrentTree(
			child,
			dictionary,
			isShowingBlankDraftsEnabled,
			selectedPageId,
			isWhiteboardEmojiFFEnabled,
		),
	);

	return dictionary;
};

/**
 * Flattens the root level pages (including orphans and space home page), and
 * creates a virtual root that all root level pages are descended from.
 *
 * @param {object} rootLevel
 * @param {object} dictionary
 */
export const flattenRootLevelTree = (
	rootLevel,
	dictionary = {},
	immutable = true,
	isShowingBlankDraftsEnabled,
	selectedPageId,
) => {
	const rootLevelPages = getPages(rootLevel, isShowingBlankDraftsEnabled, selectedPageId).map(
		(page) => {
			const rootLevelPage = createTreeItem(page, isShowingBlankDraftsEnabled, selectedPageId);
			dictionary[page.id] = rootLevelPage;

			return rootLevelPage;
		},
	);

	dictionary[VIRTUAL_ROOT_ID] = {
		id: VIRTUAL_ROOT_ID,
		children: getIds(rootLevelPages),
		parentId: null,
		hasChildren: true,
		isExpanded: true,
		isChildrenLoading: false,
		hasRestrictions: false,
		hasInheritedRestrictions: false,
		data: {
			title: '',
			status: '',
			blank: false,
			webui: '',
		},
	};

	dictionary[VIRTUAL_LOCAL_ROOT_ID] = {
		id: VIRTUAL_LOCAL_ROOT_ID,
		children: [],
		parentId: VIRTUAL_ROOT_ID,
		hasChildren: true,
		isExpanded: true,
		isChildrenLoading: false,
		hasRestrictions: false,
		hasInheritedRestrictions: false,
		data: {
			title: '',
			status: '',
			blank: false,
			webui: '',
		},
	};

	if (immutable) {
		dictionary = populateParents(dictionary);
	}

	return dictionary;
};

/**
 * Takes pagetree payload and transforms it to a structure consumable by
 * confluence/tree.
 */
export const flattenTreeData = (
	currentPage,
	rootLevelPages,
	isShowingBlankDraftsEnabled,
	selectedPageId?,
	isWhiteboardEmojiFFEnabled = false,
): TreeObjectItems<ContentTreeItem> => {
	let pages: TreeObjectItems<ContentTreeItem> = {};

	if (!currentPage) {
		return pages;
	}

	pages =
		flattenCurrentTree(
			currentPage,
			pages,
			isShowingBlankDraftsEnabled,
			selectedPageId,
			isWhiteboardEmojiFFEnabled,
		) || {};
	pages = flattenRootLevelTree(
		rootLevelPages,
		pages,
		false,
		isShowingBlankDraftsEnabled,
		selectedPageId,
	);

	const ancestors = getPages(
		currentPage.nearestAncestors,
		isShowingBlankDraftsEnabled,
		selectedPageId,
	);
	const children = getPages(currentPage.children, isShowingBlankDraftsEnabled, selectedPageId);
	const currentLevel = [
		...[
			...getPages(currentPage.previousSiblings, isShowingBlankDraftsEnabled, selectedPageId),
		].reverse(),
		{ id: currentPage.id, children },
		...getPages(currentPage.followingSiblings, isShowingBlankDraftsEnabled, selectedPageId),
	];

	// Build references upwards to current page's ancestors
	ancestors.forEach((ancestor, i) => {
		const ancestorIsParent = i === 0;

		let childrenOfAncestor: any[] = [];
		if (ancestorIsParent) {
			childrenOfAncestor = currentLevel;
		} else {
			const childOfAncestor = ancestors[i - 1];
			childrenOfAncestor = [
				...[
					...getPages(
						childOfAncestor.previousSiblings,
						isShowingBlankDraftsEnabled,
						selectedPageId,
					),
				].reverse(),
				childOfAncestor,
				...getPages(childOfAncestor.followingSiblings, isShowingBlankDraftsEnabled, selectedPageId),
			];
		}
		const page = pages[ancestor.id];
		if (page) {
			page.children = getIds(childrenOfAncestor);
		}
	});

	// Build references downwards to current page's children
	pages[currentPage.id].children = getIds(children);

	let deepestLevel = currentLevel;
	if (ancestors.length) {
		const deepestAncestor = ancestors[ancestors.length - 1];
		deepestLevel = [
			...[
				...getPages(deepestAncestor.previousSiblings, isShowingBlankDraftsEnabled, selectedPageId),
			].reverse(),
			deepestAncestor,
			...getPages(deepestAncestor.followingSiblings, isShowingBlankDraftsEnabled, selectedPageId),
		];
	}
	pages[VIRTUAL_LOCAL_ROOT_ID].children = getIds(deepestLevel);

	pages = populateParents(pages);

	return pages;
};

export const transformUIAttributes = (
	pages,
	currentPageId,
	expandedPages,
	loadingPages,
	unreadPages,
	highlightPages,
): TreeObjectItems<ContentTreeItem> =>
	produce<TreeObjectItems<ContentTreeItem>>(pages, (transformedPages) => {
		for (const id in transformedPages) {
			if (transformedPages.hasOwnProperty(id)) {
				const transformedPage = transformedPages[id];
				transformedPage.isExpanded = expandedPages.has(id);
				transformedPage.isChildrenLoading = loadingPages.has(id);
				transformedPage.data.isUnread = unreadPages.has(id);
				transformedPage.data.isSelected = transformedPage.id === currentPageId;
				transformedPage.data.isHighlighted = highlightPages?.includes(transformedPage.id) || false;
			}
		}
	});

export const populateParents = (pages): TreeObjectItems<ContentTreeItem> =>
	produce<TreeObjectItems<ContentTreeItem>>(pages, (newPages) => {
		const pageIds = Object.keys(newPages);
		// assign parent id's based on parents children
		pageIds.forEach((id) => {
			const page = newPages[id];
			if (page && page.children && page.children.length) {
				page.children.forEach((child) => {
					if (
						typeof child === 'string' &&
						// In some cases one page can have both VIRTUAL_LOCAL_ROOT_ID and VIRTUAL_ROOT_ID as parent.
						// In this situation VIRTUAL_LOCAL_ROOT_ID has higher priority.
						!(page.id === VIRTUAL_LOCAL_ROOT_ID && newPages[child].parentId === VIRTUAL_ROOT_ID)
					) {
						newPages[child].parentId = page.id;
					}
				});
			}
		});
	});

export const setChildren = (
	pages,
	expandedParentId,
	childrenIds,
): TreeObjectItems<ContentTreeItem> => {
	const pagesWithChildren = produce(pages, (pagesWithChildren) => {
		pagesWithChildren[expandedParentId].children = childrenIds;
	});

	const withMovedChildren = moveChildrenIfNeeded(pagesWithChildren, childrenIds, expandedParentId);

	return populateParents(withMovedChildren);
};

const moveChildrenIfNeeded = (pages, childrenIds, latestParentId) =>
	produce(pages, (newPages) => {
		childrenIds.forEach((childId) => {
			if (
				newPages[childId] &&
				newPages[childId].parentId &&
				newPages[childId].parentId !== latestParentId
			) {
				const oldParent = newPages[newPages[childId].parentId];
				oldParent.children = without(oldParent.children, childId);
			}
		});
	});

const handleSyntheticItem = (
	syntheticItem,
	allPages: TreeObjectItems<ContentTreeItem>,
	rootId,
	syntheticItemIsDraft,
): TreeObjectItems<ContentTreeItem> => {
	allPages = { ...allPages };

	if (syntheticItem && syntheticItem.id) {
		if (
			!allPages[syntheticItem.id] &&
			!allPages[rootId].children.some((id) => id === syntheticItem.id)
		) {
			// If an item with same id isn't present in the tree we got from the server - put it at the very top.
			// Otherwise, we'll leave it where it was, and just override its properties
			allPages[rootId] = {
				...allPages[rootId],
				children: [syntheticItem.id, ...allPages[rootId].children],
			};
		}

		const existingPageData = allPages[syntheticItem.id]?.data || {};

		allPages[syntheticItem.id] = {
			...syntheticItem,
			data: syntheticItemIsDraft
				? {
						...existingPageData,
						...syntheticItem.data,
					}
				: {
						...syntheticItem.data,
						...existingPageData,
					},
		};
	}
	return allPages;
};

const correctTreeFlattener = (
	currentPage,
	rootLevelPages,
	isShowingBlankDraftsEnabled,
	selectedPageId,
	isWhiteboardEmojiFFEnabled = false,
) => {
	return currentPage
		? flattenTreeData(
				currentPage,
				rootLevelPages,
				isShowingBlankDraftsEnabled,
				selectedPageId,
				isWhiteboardEmojiFFEnabled,
			)
		: flattenRootLevelTree(
				rootLevelPages,
				undefined,
				undefined,
				isShowingBlankDraftsEnabled,
				selectedPageId,
			);
};

const determineInitiallyExpandedPages = //only gets doesn't set.
	(pages, expandedPages, currentPage, pageTreeStateUpdatesContainer) => {
		/**
		 * Determines the initially expanded subtree after pages are fetched.
		 * The current page's ancestors are the only pages that will have children,
		 * which is how initially expanded subtree is determined below. Simply sets State
		 *
		 * @param {object} pages
		 */

		for (const id in pages) {
			const page = pages[id];
			if (page.hasChildren && childrenAreLatest(page) && page.isExpanded) {
				expandedPages.add(page.id);
			}
		}

		// Don't expand during creation flow for content that has inline
		// renaming instead of a create content route, such as folders
		const editingTitle = pageTreeStateUpdatesContainer?.state?.editingTitle;
		const isCurrentPageInCreationFlow =
			editingTitle?.isNewContent && currentPage?.id === editingTitle?.contentId;

		if (currentPage && !isCurrentPageInCreationFlow) {
			expandedPages.add(currentPage.id);
			const ancestors = currentPage.nearestAncestors.nodes;
			ancestors.forEach((page) => {
				expandedPages.add(page.id);
			});
		}

		return expandedPages;
	};

export const derriveStateFromData = (
	data,
	spaceKey,
	state,
	isPeekingFromBlogs,
	syntheticItem,
	syntheticItemIsDraft,
	isShowingBlankDraftsEnabled,
	isUnreadPagesEnabled,
	selectedPageId?,
	pageTreeStateUpdatesContainer?,
	isWhiteboardEmojiFFEnabled = false,
) => {
	const { currentPage, rootLevelPages, space } = data; //need to determine if there is no homepage, if not we must populate rootLevelPages / syntheticLocalRoot
	const serverPages = correctTreeFlattener(
		currentPage,
		rootLevelPages,
		isShowingBlankDraftsEnabled,
		selectedPageId,
		isWhiteboardEmojiFFEnabled,
	);
	const cursors = getDirectionalCursors(currentPage, {});
	// @ts-ignore
	let allPages = mergePages(state.pages, serverPages);
	const spaceHomePageId = space?.homepage?.id;

	const rootId = getRootId(allPages, currentPage?.id, spaceHomePageId);

	//set the space overview root now that we have it
	//"noHomepageFound" prevents infinite loop if space.homepage.id is undefined & returns
	//the orphaned page root
	const blogPeekRoot = isPeekingFromBlogs
		? (space.homepage && space.homepage.id) || 'noHomepageFound'
		: undefined;

	allPages = handleSyntheticItem(syntheticItem, allPages, rootId, syntheticItemIsDraft);

	const expandedPages = determineInitiallyExpandedPages(
		allPages,
		state.expandedPages,
		currentPage,
		pageTreeStateUpdatesContainer,
	);
	const newState = {
		rootId,
		pages: allPages,
		space,
		spaceKey,
		expandedPages,
		isTreeTruncatedPrevious: isPageTreeTruncatedPrevious(currentPage),
		isTreeTruncatedFollowing: isPageTreeTruncatedFollowing(currentPage),
		cursors,
		initialPages: currentPage,
		blogPeekRoot,
		isShowingBlankDraftsEnabled,
		isUnreadPagesEnabled,
	};
	return newState;
};
