import gql from 'graphql-tag';

const PageTreeInfoFragment = gql`
	fragment PageTreeInfoFragment on PTPage {
		id
		title
		status
		createdDate(format: MILLIS) {
			value
		}
		blank
		hasChildren
		hasRestrictions @include(if: $isSuperAdmin)
		hasInheritedRestrictions @include(if: $isSuperAdmin)
		type
		links {
			webui
		}
		properties(keys: ["emoji-title-published", "emoji-title-draft", "editor"]) {
			nodes {
				id
				key
				value
				version {
					number
				}
			}
		}
	}
`;

/*
  If you add an optional property to PageTreeInfoFragment then you need to duplicate this query.
  See https://product-fabric.atlassian.net/browse/SCALE-4250 for more details
*/

export const PageTreeChildrenQuery = gql`
	query PageTreeChildrenQuery(
		$contentId: ID!
		$statuses: [PTGraphQLPageStatus]
		$first: Int
		$after: String
		$isSuperAdmin: Boolean!
	) {
		ptpage(id: $contentId, status: $statuses) {
			...PageTreeInfoFragment
			children(first: $first, after: $after) {
				nodes {
					...PageTreeInfoFragment
				}
				pageInfo {
					hasNextPage
					endCursor
				}
			}
		}
	}

	${PageTreeInfoFragment}
`;
