// This type matches the GraphQL result from fetching something with `properties`.
interface PageType {
	properties: {
		nodes:
			| ({
					key: string | null;
					value: string | null;
			  } | null)[]
			| null;
	} | null;
}
export type EditorPropertyType = 'v1' | 'v2' | '';

// This function is meant to fetch the editor version from a page's properties list.
// This returns 3 values: "v1" (for TinyMCE) or "v2" (for Fabric), or "" if it could not be found
export const getEditorVersionFromPageProperties = (page?: PageType | null): EditorPropertyType => {
	const editorProperty = page?.properties?.nodes?.find((prop) => prop?.key === 'editor');
	const editorVersion = (editorProperty?.value || '').replace(/['"]+/g, '');

	if (editorVersion !== 'v1' && editorVersion !== 'v2') {
		return '';
	} else {
		return editorVersion;
	}
};
