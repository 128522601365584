import React from 'react';
import { FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N100 } from '@atlaskit/theme/colors';

import type { ContentTreeItem } from './data-transformers';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TreeItemEmptyState = styled.div<{ indent?: number }>({
	display: 'flex',
	alignItems: 'center',
	cursor: 'default',
	height: '36px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingLeft: ({ indent }) => (indent ? `${indent}px` : '0px'),
	color: token('color.text.subtlest', N100),
});

export const renderItemEmptyState = ({
	item,
	indent,
}: {
	item: ContentTreeItem;
	indent?: number;
}) => {
	if (item.data.type === 'folder') {
		return (
			<TreeItemEmptyState indent={indent}>
				<FormattedMessage
					id="page-tree.folder.empty-state"
					defaultMessage="There’s nothing in this folder yet."
					description="Text for the empty state when expanding a folder with no children"
				/>
			</TreeItemEmptyState>
		);
	}
};
