import { differenceInDays } from 'date-fns';

type PageData = {
	contentType?: string;
	createdDate?: Date;
	status?: string;
};

/** Checks whether a page is published and recently created (within the past week), for determining unread status. */
export function isRecentPublishedPage({ contentType, createdDate, status }: PageData): boolean {
	const RECENT_PAGE_MAX_AGE_DAYS = 7;
	const isPage = contentType === 'page';
	const isPublished = status?.toLowerCase() === 'current';
	const isRecent =
		!!createdDate && differenceInDays(Date.now(), createdDate) < RECENT_PAGE_MAX_AGE_DAYS;

	return isPage && isRecent && isPublished;
}
