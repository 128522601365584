import { useIsNav4Enabled } from '@confluence/nav4-enabled';

export const DragDropEndTrackEvent = ({
	source,
	contentStatus,
	pageId,
	previousParentPageId,
	newParentPageId,
	previousSpaceId,
	newSpaceId,
	referrer,
	isLivePage,
	isOnEditRoute,
}: {
	source: string;
	contentStatus: string | null;
	pageId: string;
	previousParentPageId: string;
	newParentPageId: string;
	previousSpaceId: string | undefined | null;
	newSpaceId: string | undefined | null;
	referrer?: string;
	isLivePage?: boolean; //TODO optional for pageTree
	isOnEditRoute?: boolean;
}) => ({
	type: 'sendTrackEvent',
	data: {
		action: 'updated',
		source,
		containerType: 'space',
		containerId: previousSpaceId,
		objectType: 'page',
		objectId: pageId,
		actionSubject: 'page',
		attributes: {
			contentStatus: contentStatus ?? null,
			previousSpaceId,
			previousParentPageId,
			newSpaceId,
			newParentPageId,
			...(referrer ? { referrer } : undefined),
			isLivePage,
			isOnEditRoute,
		},
	},
});

export const DragStartUIEvent = () => ({
	type: 'sendUIEvent',
	data: {
		action: 'dragged',
		actionSubject: 'pageTree',
		source: 'viewPageScreen',
	},
});

export const DragEndUIEvent = (isValidTarget) => ({
	type: 'sendUIEvent',
	data: {
		action: 'dropped',
		actionSubject: 'pageTree',
		source: 'viewPageScreen',
		attributes: {
			isValidTarget,
		},
	},
});

export const UndoClickUIEvent = () => ({
	type: 'sendUIEvent',
	data: {
		action: 'clicked',
		actionSubject: 'button',
		actionSubjectId: 'pageTreeDragDropUndo',
		source: 'viewPageScreen',
	},
});

export const UndoShortcutUIEvent = () => ({
	type: 'sendUIEvent',
	data: {
		action: 'pressed',
		actionSubject: 'keyboardShortcut',
		actionSubjectId: 'pageTreeDragDropUndo',
		source: 'viewPageScreen',
	},
});

export const PageTreeUIAnalytics = (
	action,
	spaceId,
	isInDrawer,
	status?,
	contentId?,
	contentType?,
	navdexPointType?,
	isOnEditRoute?,
	isUnreadContent?,
	contentTreeSize?,
) => {
	const isNav4Enabled = useIsNav4Enabled();
	const event = {
		type: 'sendUIEvent',
		data: {
			containerType: 'space',
			containerId: spaceId,
			objectType: contentType ?? 'page',
			objectId: contentId ?? '',
			action,
			actionSubject: 'pageTree',
			source: isInDrawer ? 'pageTreeDrawer' : 'containerNavigation',
			attributes: {
				navigationLayer: 'container',
				navigationContainer: 'sidebar',
				spaceView: 'TREE',
				contentStatus: status ?? null, // status/contentStatus is used to distinguish published page vs draft when clicking on a page tree item
				isOnEditRoute,
				isUnreadContent,
				contentTreeSize,
				navVersion: isNav4Enabled ? '4' : '3',
			},
		},
	};
	if (navdexPointType) {
		event.data.attributes['navdexPointType'] = navdexPointType;
	}
	return event;
};

export const PageTreePaginationEvent = (spaceId?, pageCount?, source?, direction?) => ({
	type: 'sendUIEvent',
	data: {
		action: 'clicked',
		containerId: spaceId,
		actionSubject: 'button',
		actionSubjectId: 'showAllButton',
		source: source || 'containerNavigation',
		attributes: {
			pageCount,
			direction,
		},
	},
});
