import gql from 'graphql-tag';

export const PageTreeRootLevelPages = gql`
	query PageTreeRootLevelPages(
		$spaceKey: String!
		$statuses: [GraphQLPageStatus]
		$isSuperAdmin: Boolean!
		$paginationLimit: Int
	) {
		rootLevelPages: pages(spaceKey: $spaceKey, status: $statuses, limit: $paginationLimit) {
			nodes {
				id
				hasChildren
				title
				status
				blank
				hasRestrictions @include(if: $isSuperAdmin)
				hasInheritedRestrictions @include(if: $isSuperAdmin)
				type
				properties(keys: ["emoji-title-published", "emoji-title-draft", "editor"]) {
					nodes {
						id
						key
						value
						version {
							number
						}
					}
				}
				links {
					webui
				}
			}
		}
		space(key: $spaceKey) {
			id
			homepage {
				id
			}
			operations {
				operation
				targetType
			}
		}
	}
`;
