import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	spotlightBody: {
		id: 'experiment-sample-content.spotlight-body',
		defaultMessage:
			'Accelerate brainstorming, project planning, and task-tracking by exploring our curated content or start by creating your own.',
		description: 'Body text for sample content experiment spotlight',
	},
	spotlightHeadingUpdated: {
		id: 'experiment-sample-content.spotlight-heading-updated',
		defaultMessage: 'Your Confluence journey starts here',
		description: 'Heading for sample content experiment spotlight',
	},
	spotlightDismissButton: {
		id: 'experiment-sample-content.spotlight-dismiss-button',
		defaultMessage: 'Dismiss',
		description: 'Dismiss button text for sample content experiment spotlight',
	},
});
