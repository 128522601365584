import React, { useCallback, useState, useEffect, useRef } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { Checkbox } from '@atlaskit/checkbox';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { OnInteractionData } from '@confluence/satisfaction-survey';
import { SatisfactionSurveyFlag } from '@confluence/satisfaction-survey';
import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER as persistedLocalStorageKeys,
} from '@confluence/storage-manager';

const i18n = defineMessages({
	surveyTitle: {
		id: 'folder.create.satisfaction-survey.title',
		defaultMessage: 'Help us improve folders',
		description: 'Title text for the folder creation satisfaction survey form',
	},
	surveyDescription: {
		id: 'folder.create.satisfaction-survey.description',
		defaultMessage: 'Rate your experience creating a folder:',
		description: 'Description text for the folder creation satisfaction survey form',
	},
	canContactUser: {
		id: 'folder.create.satisfaction-survey.can-contact.label',
		defaultMessage: 'Atlassian can contact me about this feedback. See our {privacyPolicyLink}.',
		description:
			"Text next to first checkbox. {privacyPolicyLink} will be substituted with a link whose text reads 'privacy policy'",
	},
	terribleRating: {
		id: 'folder.create.satisfaction-survey.rating.terrible',
		defaultMessage: 'Terrible',
		description: 'Tooltip to display for the survey rating of terrible',
	},
	badRating: {
		id: 'folder.create.satisfaction-survey.rating.bad',
		defaultMessage: 'Bad',
		description: 'Tooltip to display for the survey rating of bad',
	},
	okayRating: {
		id: 'folder.create.satisfaction-survey.rating.okay',
		defaultMessage: 'Okay',
		description: 'Tooltip to display for the survey rating of okay',
	},
	greatRating: {
		id: 'folder.create.satisfaction-survey.rating.great',
		defaultMessage: 'Great',
		description: 'Tooltip to display for the survey rating of great',
	},
	amazingRating: {
		id: 'folder.create.satisfaction-survey.rating.amazing',
		defaultMessage: 'Amazing',
		description: 'Tooltip to display for the survey rating of amazing',
	},
	satisfactionSurveyQuestion: {
		id: 'folder.create.satisfaction-survey.question',
		defaultMessage: 'Tell us more about your experience:',
		description: 'Text that prompts the user for more details on their rating',
	},
	surveySubmit: {
		id: 'folder.create.satisfaction-survey.submit',
		defaultMessage: 'Submit',
		description: 'Submit button text for the folder creation satisfaction survey form',
	},
	surveyCancel: {
		id: 'folder.create.satisfaction-survey.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button text for the folder creation satisfaction survey form',
	},
	surveyConfirmation: {
		id: 'folder.create.satisfaction-survey.confirmation',
		defaultMessage: 'We’ve recorded your response',
		description:
			'Title text for the folder creation satisfaction survey form after the user has submitted',
	},
	surveyConfirmationDescription: {
		id: 'folder.create.satisfaction-survey.confirmation.description',
		defaultMessage: 'Thanks for taking the time to share your feedback with us.',
		description:
			'Description text for the folder creation satisfaction survey form after the user has submitted',
	},
	privacyPolicyLink: {
		id: 'folder.create.satisfaction-survey.privacy-policy.link',
		defaultMessage: 'privacy policy',
		description: 'The text of the link which will help user go to the privacy policy site.',
	},
});

export const STORAGE_KEY_HAS_SEEN_CREATE_FOLDER_SURVEY =
	persistedLocalStorageKeys.PERSISTED_CREATE_FOLDER_SATISFACTION_SURVEY_HAS_SEEN;

const markSurveySeen = () => {
	localStorage?.setItem(STORAGE_KEY_HAS_SEEN_CREATE_FOLDER_SURVEY, true);
};

type CreateFolderSatisfactionSurveyProps = {
	onCloseSurvey?: () => void;
};

export const CreateFolderSatisfactionSurvey = ({
	onCloseSurvey,
}: CreateFolderSatisfactionSurveyProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isOnFinalStage, setIsOnFinalStage] = useState(false);
	const [canContactUser, setCanContactUser] = useState(false);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'shown',
				actionSubject: 'survey',
				actionSubjectId: 'createFolderFeedbackSurvey',
				source: 'createFolderFeedbackSurveyModal',
			},
		}).fire();
	}, [createAnalyticsEvent]);

	// "hasFiredSubmitEventRef" flag is separate from "isOnFinalStage" so we only fire the event once
	// and only re-render modal contents in the "final" mode after the event has actually been fired
	const hasFiredSubmitEventRef = useRef<boolean>(false);

	const handleSurveyInteraction = useCallback(
		({ reaction, feedback, submitted, dismissed }: OnInteractionData) => {
			void markSurveySeen();
			if (dismissed) {
				onCloseSurvey?.();
			} else if (submitted && !hasFiredSubmitEventRef.current) {
				hasFiredSubmitEventRef.current = true;
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'submitted',
						actionSubject: 'survey',
						actionSubjectId: 'createFolderFeedbackSurvey',
						source: 'createFolderFeedbackSurveyModal',
						attributes: {
							canContact: canContactUser,
							rating: reaction?.value || null,
							feedback,
						},
					},
				}).fire();
				setIsOnFinalStage(true);
			}
		},
		[createAnalyticsEvent, onCloseSurvey, setIsOnFinalStage, canContactUser],
	);

	const PrivacyPolicyLink = ({ link }: { link: string }) => (
		<a target="_blank" href={link}>
			<FormattedMessage {...i18n.privacyPolicyLink} />
		</a>
	);

	const canContactUserCheckbox = (
		<Checkbox
			isChecked={canContactUser}
			label={
				<FormattedMessage
					{...i18n.canContactUser}
					values={{
						privacyPolicyLink: (
							<PrivacyPolicyLink link="https://www.atlassian.com/legal/privacy-policy" />
						),
					}}
				/>
			}
			name="can-contact-checkbox"
			onChange={(event) => {
				setCanContactUser(event.currentTarget.checked);
			}}
		/>
	);

	const reactionQuestions: string[] = Array(5).fill(formatMessage(i18n.satisfactionSurveyQuestion));

	const reactionTitles: string[] = [
		formatMessage(i18n.terribleRating),
		formatMessage(i18n.badRating),
		formatMessage(i18n.okayRating),
		formatMessage(i18n.greatRating),
		formatMessage(i18n.amazingRating),
	];

	return (
		<SatisfactionSurveyFlag
			title={formatMessage(i18n.surveyTitle)}
			description={formatMessage(i18n.surveyDescription)}
			finalTitle={formatMessage(i18n.surveyConfirmation)}
			finalDescription={formatMessage(i18n.surveyConfirmationDescription)}
			reactionTitles={reactionTitles}
			formQuestions={reactionQuestions}
			submitButtonText={formatMessage(i18n.surveySubmit)}
			closeButtonText={formatMessage(i18n.surveyCancel)}
			isOnFinalStage={isOnFinalStage}
			onInteraction={handleSurveyInteraction}
			customFields={canContactUserCheckbox}
		/>
	);
};
