import gql from 'graphql-tag';

export const MovePageTopLevelMutation = gql`
	mutation MovePageTopLevelMutation($pageId: ID!, $spaceKey: String!) {
		movePageTopLevel(input: { pageId: $pageId, targetSpaceKey: $spaceKey }) {
			page {
				id
				links {
					webui
					editui
				}
			}
		}
	}
`;
