import { LoadableAfterPaint } from '@confluence/loadable';

export const EmptySidebarCollapsibleSectionLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EmptySidebarCollapsibleSectionContainer" */ '../src/CollapsibleSection'
			)
		).EmptySidebarCollapsibleSectionContainerWrapper,
});
