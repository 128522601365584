import isEqual from 'lodash/isEqual';
import { useCallback } from 'react';

import type { TreeDestinationPosition, TreeSourcePosition } from '@confluence/tree';

import type { ContentTreeItem } from './data-transformers';
import type { PageTreeCoordinatorProps } from './PageTreeCoordinator';
import type { MovePageParams } from './useMovePageHandler';
import { usePageTreeState } from './usePageTreeState';

const determineMovePageParams = (
	destinationParent: ContentTreeItem,
	pageId,
	destination,
): MovePageParams => {
	if (typeof destination.index === 'undefined') {
		return {
			pageId,
			parentId: destination.parentId,
		};
	}
	const movedToFirstPosition = destination.index === 0;
	const currentIndexUnderDestinationParent = destinationParent.children.indexOf(pageId);
	const movedDownOnSameLevel =
		currentIndexUnderDestinationParent > -1 &&
		currentIndexUnderDestinationParent < destination.index;
	const isSiblingShifted = movedToFirstPosition || movedDownOnSameLevel;
	const siblingIndex = isSiblingShifted ? destination.index : destination.index - 1;
	const siblingId = String(destinationParent.children[siblingIndex]);

	return {
		pageId,
		siblingId,
		positionToSibling: destination.index === 0 ? 'before' : 'after',
	};
};

const validateMove = (source: TreeSourcePosition, destination: TreeDestinationPosition, pages) => {
	if (!destination || isEqual(source, destination) || !pages[destination.parentId]) {
		return 'INVALID_MOVE_LOCATION';
	}
	if (pages[destination.parentId].data.status === 'DRAFT') {
		return 'INVALID_MOVE_LOCATION_DRAFT';
	}
	return null;
};

type UseMovePage = ({
	onMoveInvalid,
	syntheticItem,
	onPageMove,
	queryChildren,
}: {
	onMoveInvalid: PageTreeCoordinatorProps['onMoveInvalid'];
	syntheticItem?: ContentTreeItem;
	onPageMove: PageTreeCoordinatorProps['onPageMove'];
	queryChildren;
}) => (source: TreeSourcePosition, destination: TreeDestinationPosition) => void;

export const useMovePage: UseMovePage = ({
	onMoveInvalid,
	syntheticItem,
	onPageMove,
	queryChildren,
}) => {
	// Note: we are not extracting state at this point as we don't need it for rendering
	// The latest state is requested using `actions.getState()` inside of
	// callbacks to avoid needing to break memoization 🚀
	const [_, actions] = usePageTreeState();

	const movePage = useCallback(
		(source: TreeSourcePosition, destination: TreeDestinationPosition) => {
			// The reference to our state changes fairly frequently.
			// Given that `movePage` only needs the state when it is called, we can
			// retrieve the latest state from the store when it is needed.
			// By doing this we can keep the reference to `movePage` more stable
			const { pages, space } = actions.getState();

			// In the current scope we only move pages between expanded subtree
			const reason = validateMove(source, destination, pages);
			if (reason) {
				onMoveInvalid?.({ reason });
				return;
			}

			const pageId = syntheticItem?.id || pages[source.parentId].children[source.index];
			const contentStatus = pages[pageId]?.data?.status || null;

			const destinationParent = pages[destination.parentId];
			const sourceParent = pages[source.parentId];

			// We optimistically update the tree & history by moving the page in state.
			// We can only expand the parent afterwards if the moved page is the synthetic item,
			// otherwise we might expand before the backend updates, resulting in a disappeared page.
			const expandAfter = pageId === syntheticItem?.id;
			actions.movePageInState(pageId, source, destination, queryChildren, expandAfter);

			const movePageParams = determineMovePageParams(destinationParent, pageId, destination);

			onPageMove(movePageParams, {
				contentStatus,
				destination,
				movedPageTitle: pages[pageId].data.title,
				source,
				sourceParent,
				spaceId: space?.id,
				revertMove: () => {
					// recollecting the latest state as it might have changed
					const { pages } = actions.getState();
					// get updated source parent to ensure we're placing the element next to the right sibling
					const updatedSourceParent = pages[sourceParent.id];
					const inverseDestination =
						updatedSourceParent.children.length > 0 ? source : { parentId: sourceParent.id };

					actions.movePageInState(pageId, destination, inverseDestination, true, true);

					return determineMovePageParams(updatedSourceParent, pageId, inverseDestination);
				},
			});
		},
		[actions, onMoveInvalid, onPageMove, queryChildren, syntheticItem?.id],
	);

	return movePage;
};
