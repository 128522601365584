import gql from 'graphql-tag';

export const MovePageBeforeMutation = gql`
	mutation MovePageBeforeMutation($pageId: ID!, $siblingId: ID!) {
		movePageBefore(input: { pageId: $pageId, siblingId: $siblingId }) {
			page {
				id
				links {
					webui
					editui
				}
			}
		}
	}
`;
