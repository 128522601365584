import { useCallback, useEffect, useState } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

class OptimisticTitleChangeEventBus {
	private listeners: Record<string, ((title: string) => void)[]>;

	constructor() {
		this.listeners = {};
	}

	emit(contentId: string, title: string) {
		if (this.listeners[contentId]) {
			this.listeners[contentId].forEach((fn) => {
				fn(title);
			});
		}
	}

	subscribe(contentId: string, fn: (title: string) => void) {
		if (!this.listeners[contentId]) {
			this.listeners[contentId] = [];
		}

		this.listeners[contentId].push(fn);
	}

	unsubcribe(contentId: string, fn: (title: string) => void) {
		if (this.listeners[contentId]) {
			const index = this.listeners[contentId].findIndex((item) => item[0] === fn);
			if (index !== -1) {
				this.listeners[contentId].splice(index, 1);
			}
		}
	}
}

type OptimisticTitleChangeData = {
	optimisticTitle: string | null;
	setOptimisticTitle: (title: string) => void;
};

const isOptimisticTitleEnabled = fg('confluence_optimistic_pagetree_title_update');
const optimisticTitleChangeEventBus = isOptimisticTitleEnabled
	? new OptimisticTitleChangeEventBus()
	: null;

// If feature flag is off, this hook should behave as a no-op
// (always return initial title, and set should do nothing)
const noOpUseOptimisticChange = (
	_: string,
	initialTitle: string | null,
): OptimisticTitleChangeData => {
	return {
		optimisticTitle: initialTitle,
		setOptimisticTitle: (_: string | null) => {},
	};
};

const useOptimisticTitleChangeImpl = (
	contentId: string,
	initialTitle: string | null,
): OptimisticTitleChangeData => {
	const [optimisticTitle, setOptimisticTitle] = useState(initialTitle);

	const publishOptimisticTitle = useCallback(
		(value: string) => {
			optimisticTitleChangeEventBus && optimisticTitleChangeEventBus.emit(contentId, value);
		},
		[contentId],
	);

	useEffect(() => {
		const handleTitleChanged = (title: string) => {
			setOptimisticTitle(title);
		};

		optimisticTitleChangeEventBus &&
			optimisticTitleChangeEventBus.subscribe(contentId, handleTitleChanged);
		return () => {
			optimisticTitleChangeEventBus &&
				optimisticTitleChangeEventBus.unsubcribe(contentId, handleTitleChanged);
		};
	}, [contentId, setOptimisticTitle]);

	return {
		optimisticTitle,
		setOptimisticTitle: publishOptimisticTitle,
	};
};

export const useOptimisticTitleChange = isOptimisticTitleEnabled
	? useOptimisticTitleChangeImpl
	: noOpUseOptimisticChange;
