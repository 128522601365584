import gql from 'graphql-tag';

export const MovePageAfterMutation = gql`
	mutation MovePageAfterMutation($pageId: ID!, $siblingId: ID!) {
		movePageAfter(input: { pageId: $pageId, siblingId: $siblingId }) {
			page {
				id
				links {
					webui
					editui
				}
			}
		}
	}
`;
