import { useCallback } from 'react';

import { PageTreePreviousItemsQuery } from './queries/PageTreePreviousItemsQuery.graphql';
import { PageTreeFollowingItemsQuery } from './queries/PageTreeFollowingItemsQuery.graphql';
import { usePageTreeState } from './usePageTreeState';
import { useImperativeQuery } from './useImperativeQuery';
import {
	isPageTreeTruncatedPrevious,
	isPageTreeTruncatedFollowing,
	mergePreviousPages,
	mergeFollowingPages,
	getDirectionalCursors,
} from './data-extractors';
import type { GraphQLPageStatus } from './pageTreeStatuses';
import { INITIAL_PAGE_SIZE } from './paginationLimits';

type direction = 'previous' | 'following';

type useLoadMorePages = ({
	statuses,
}: {
	statuses?: GraphQLPageStatus[];
	direction?: direction;
}) => {
	loadAllPages: (
		contentId: string,
		spaceKey: string,
		isSuperAdmin: boolean,
		cursors: any,
		allPages: any,
	) => any;
};

const isPageTreeTruncatedDirectionally = {
	previous: isPageTreeTruncatedPrevious,
	following: isPageTreeTruncatedFollowing,
};

const mergePages = {
	previous: mergePreviousPages,
	following: mergeFollowingPages,
};

export const useLoadMorePages = ({ statuses }) => {
	const [_, actions] = usePageTreeState();

	const getMorePreviousPages = useImperativeQuery(PageTreePreviousItemsQuery);
	const getMoreFollowingPages = useImperativeQuery(PageTreeFollowingItemsQuery);

	const loadMorePages = useCallback(
		(
			contentId: any,
			spaceKey: any,
			isSuperAdmin: any,
			cursors: any,
			allPages: any,
			direction: direction = 'following',
			previousCounts = {
				children: 0,
				previousSiblings: 0,
				followingSiblings: 0,
			},
		) => {
			const id = cursors?.[direction]?.id || undefined;
			const ancestorIndex = cursors?.[direction]?.ancestorIndex || undefined;
			const directionalGetMorePages = {
				previous: getMorePreviousPages,
				following: getMoreFollowingPages,
			}[direction];

			return directionalGetMorePages({
				contentId: id,
				paginationLimit: INITIAL_PAGE_SIZE,
				spaceKey,
				isSuperAdmin,
				statuses,
				ptStatuses: statuses,
			})
				.then((response) => {
					const { data, error } = response as any;
					if (error) {
						actions.handleLoadingError(error, direction);
						return;
					} else {
						if (data && Object.keys(data).length > 0) {
							const { currentPage, rootLevelPages } = data;
							const mergedPages = mergePages[direction](currentPage, allPages, ancestorIndex);
							const childrenLength = mergedPages.children.count;
							const previousSiblingsLength = mergedPages.previousSiblings.count;
							const followingSiblingsLength = mergedPages.followingSiblings.count;
							//infinite loop check:
							if (
								childrenLength > previousCounts.children ||
								previousSiblingsLength > previousCounts.previousSiblings ||
								followingSiblingsLength > previousCounts.followingSiblings
							) {
								//congrats one of the counts got bigger, paging was worth it
								if (isPageTreeTruncatedDirectionally[direction](currentPage)) {
									const nextCursors = getDirectionalCursors(currentPage, cursors, direction);
									return loadMorePages(
										contentId,
										spaceKey,
										isSuperAdmin,
										nextCursors,
										mergedPages,
										direction,
										{
											children: childrenLength,
											previousSiblings: previousSiblingsLength,
											followingSiblings: followingSiblingsLength,
										},
									);
								}
							}
							return { mergedPages, rootLevelPages };
						}
					}
				})
				.catch((error) => {
					actions.handleLoadingError(error, direction);
					return { error };
				});
		},
		[getMorePreviousPages, getMoreFollowingPages, actions, statuses],
	);

	return { loadMorePages };
};
