import { useAnalyticsEvents, type AnalyticsEventPayload } from '@atlaskit/analytics-next';

enum EVENT_TYPE {
	OPERATIONAL = 'sendOperationalEvent',
	SCREEN = 'sendScreenEvent',
	TRACK = 'sendTrackEvent',
	UI = 'sendUIEvent',
}

type FireAnalyticsEventFn = (payload: AnalyticsEventPayload) => void;

type FireAnalyticsEvent = {
	fireUIAnalyticsEvent: FireAnalyticsEventFn;
	fireTrackAnalyticsEvent: FireAnalyticsEventFn;
	fireOperationalAnalyticsEvent: FireAnalyticsEventFn;
	fireScreenAnalyticsEvent: FireAnalyticsEventFn;
};

export const useAnalytics = (): FireAnalyticsEvent => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireAnalyticsEvent = (eventType: EVENT_TYPE) => (payload: AnalyticsEventPayload) => {
		const analyticsEvent = createAnalyticsEvent({
			type: eventType,
			data: {
				source: 'sample-content-experiment',
				...payload,
			},
		});
		analyticsEvent.fire();
	};

	return {
		fireUIAnalyticsEvent: fireAnalyticsEvent(EVENT_TYPE.UI),
		fireTrackAnalyticsEvent: fireAnalyticsEvent(EVENT_TYPE.TRACK),
		fireOperationalAnalyticsEvent: fireAnalyticsEvent(EVENT_TYPE.OPERATIONAL),
		fireScreenAnalyticsEvent: fireAnalyticsEvent(EVENT_TYPE.SCREEN),
	};
};
