import gql from 'graphql-tag';

export const MovePageAppendMutation = gql`
	mutation MovePageAppendMutation($pageId: ID!, $parentId: ID!) {
		movePageAppend(input: { pageId: $pageId, parentId: $parentId }) {
			page {
				id
				links {
					webui
					editui
				}
			}
		}
	}
`;
