import {
	PAGE_TREE_SEGMENT_KEY,
	createPageSegmentLoadMetric,
	MOVE_PAGE_DIALOG_INTERACTION,
	createInteractionMetric,
} from '@confluence/browser-metrics';
export const PAGE_TREE_METRIC = createPageSegmentLoadMetric({
	key: PAGE_TREE_SEGMENT_KEY,
	ssr: Boolean(window.__SSR_RENDERED__) ? { doneAsFmp: true } : undefined,
});

export const MOVE_PAGE_DIALOG_INTERACTION_METRIC = createInteractionMetric({
	key: MOVE_PAGE_DIALOG_INTERACTION,
});
