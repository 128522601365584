import React, { useState, useEffect, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl-next';

import { Spotlight, SpotlightTransition } from '@atlaskit/onboarding';
import { usePageLayoutResize } from '@atlaskit/page-layout';
import { useThemeObserver } from '@atlaskit/tokens';

import {
	ONBOARDING_TRACKER_ORGANIZE_SPACE_SPOTLIGHT,
	ExperienceStart,
} from '@confluence/experience-tracker';
import { TransparentErrorBoundary, Attribution } from '@confluence/error-boundary';

import SpotlightContentTree from '../assets/SpotlightContentTree.svg';
import SpotlightContentTreeDark from '../assets/SpotlightContentTree-dark.svg';

import { i18n } from '../OnboardingTrackerI18n';
import { useOnboardingTrackerEligible } from '../hooks/useOnboardingTrackerEligible';
import { useOnboardingTrackerExperienceTracker } from '../OnboardingTrackerEvents';
import { TRACKERSPOTLIGHT_PAGETREE } from '../OnboardingTrackerConstants';

import { useTrackerSpotlights } from './useTrackerSpotlights';

export const OnboardingTrackerPageTreeSpotlight = ({
	contentTreeSize,
}: {
	contentTreeSize?: number;
}) => {
	const { formatMessage } = useIntl();
	const { colorMode } = useThemeObserver();
	const { isLeftSidebarCollapsed, expandLeftSidebar } = usePageLayoutResize();
	const [
		{ isPageTreeContentSpotlightActive, isNextPageTreeContentSpotlight },
		{
			closePageTreeContentSpotlight,
			showPageTreeContentSpotlight,
			setIsNextPageTreeContentSpotlight,
		},
	] = useTrackerSpotlights();
	const { finishOnboardingTrackerSpotlight, failOnboardingTrackerSpotlight } =
		useOnboardingTrackerExperienceTracker();
	const { isOnboardingTrackerExperimentEligible } = useOnboardingTrackerEligible();
	const [shouldShowPageTreeSpotlight, setShouldShowPageTreeSpotlight] = useState(false);

	const handleTargetOnClick = useCallback(() => {
		void closePageTreeContentSpotlight();

		finishOnboardingTrackerSpotlight({
			name: ONBOARDING_TRACKER_ORGANIZE_SPACE_SPOTLIGHT,
			target: TRACKERSPOTLIGHT_PAGETREE,
		});
	}, [closePageTreeContentSpotlight, finishOnboardingTrackerSpotlight]);

	const handleSpotlightError = useCallback(
		(error: Error) => {
			failOnboardingTrackerSpotlight({
				name: ONBOARDING_TRACKER_ORGANIZE_SPACE_SPOTLIGHT,
				target: TRACKERSPOTLIGHT_PAGETREE,
				error,
			});
		},
		[failOnboardingTrackerSpotlight],
	);

	useEffect(() => {
		//There are 2 two virtual folders that are always added to parent orphans. And 1 for overview page = total 3 pages not shows in pagetree
		//so if contentTreeSize >= 5, the pagetree has 2 or more items to organize
		if (contentTreeSize && contentTreeSize >= 5) {
			setShouldShowPageTreeSpotlight(true);
		}
	}, [setShouldShowPageTreeSpotlight, contentTreeSize]);

	useEffect(() => {
		if (shouldShowPageTreeSpotlight && isNextPageTreeContentSpotlight) {
			if (isLeftSidebarCollapsed) expandLeftSidebar();
			setTimeout(() => {
				void showPageTreeContentSpotlight();
			}, 2000);
			void setIsNextPageTreeContentSpotlight(false);
		}
	}, [
		isLeftSidebarCollapsed,
		expandLeftSidebar,
		shouldShowPageTreeSpotlight,
		showPageTreeContentSpotlight,
		setIsNextPageTreeContentSpotlight,
		isNextPageTreeContentSpotlight,
	]);

	return isOnboardingTrackerExperimentEligible &&
		isPageTreeContentSpotlightActive &&
		shouldShowPageTreeSpotlight ? (
		<TransparentErrorBoundary
			attribution={Attribution.CC_ONBOARDING}
			onError={handleSpotlightError}
		>
			<ExperienceStart
				name={ONBOARDING_TRACKER_ORGANIZE_SPACE_SPOTLIGHT}
				attributes={{ target: TRACKERSPOTLIGHT_PAGETREE }}
			/>
			<SpotlightTransition>
				<Spotlight
					heading={formatMessage(i18n.trackerSpotlightPageTreeContentTitle)}
					target="pagetree"
					key="onboarding-tracker-pagetree-spotlight"
					targetRadius={3}
					dialogPlacement="right middle"
					dialogWidth={275}
					image={colorMode === 'dark' ? SpotlightContentTreeDark : SpotlightContentTree}
					targetOnClick={handleTargetOnClick}
				>
					<FormattedMessage {...i18n.trackerSpotlightPageTreeContentContent} />
				</Spotlight>
			</SpotlightTransition>
		</TransparentErrorBoundary>
	) : (
		<></>
	);
};
