import { useState } from 'react';

// Using a getter rather than a value so that setting
// `isDragging` does not cause the whole tree to re-render
function create() {
	let isDragging: boolean = false;
	return {
		setIsDraggingItem(value: boolean) {
			isDragging = value;
		},
		getIsDraggingItem(): boolean {
			return isDragging;
		},
	};
}

export function useIsDragging() {
	// make a stable reference to our `api`
	const [api] = useState(create);
	return api;
}
