import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	noCreatePermissionsErrorTitle: {
		id: 'page-tree.move-page-dialog.published-page.move-error.no-create-permissions-error.title',
		defaultMessage: 'You can’t move this content',
		description:
			"The error title when a user can't move their content to the selected location because they cannot create content in the selected space",
	},
	noCreatePermissionsErrorDescription: {
		id: 'page-tree.move-page-dialog.published-page.move-error.no-create-permissions-error.description',
		defaultMessage:
			'To move content to another space, you need permission to create in that space.',
		description:
			"The error description when a user can't move their page to the selected location because they cannot create pages in the selected space",
	},
	permissionsErrorTitle: {
		id: 'page-tree.move-page-dialog.published-page.move-error.permissions-error.title',
		defaultMessage: 'You can’t move this content',
		description:
			"The error title when a user can't move their content to the selected location because they don't have the required permissions",
	},
	permissionsErrorDescription: {
		id: 'page-tree.move-page-dialog.published-page.move-error.permissions-error.description',
		defaultMessage:
			'You don’t have the required permissions to move this content to another space.',
		description:
			"The error description when a user can't move their page to the selected location because they don't have the required permissions",
	},
	parentChildLoopErrorTitle: {
		id: 'page-tree.move-page-dialog.published-page.move-error.parent-child-loop.title',
		defaultMessage: 'Select a different destination',
		description:
			"The error title when a user can't move their content to the selected parent because the selected parent is a child of their content",
	},
	parentChildLoopErrorDescription: {
		id: 'page-tree.move-page-dialog.published-page.move-error.parent-child-loop.description',
		defaultMessage: 'The destination cannot be nested below the content being moved.',
		description:
			"The error description when a user can't move their content to the selected parent because the selected parent is a child of their content",
	},
	singleConflictingContentNameErrorTitle: {
		id: 'page-tree.move-page-dialog.published-page.move-error.single-conflicting-page-name.title',
		defaultMessage: 'Content with this title already exists',
		description:
			"The error title when a user can't move their content group to the selected space because the space already contains a single content with the same name",
	},
	singleConflictingContentNameErrorDescription: {
		id: 'page-tree.move-page-dialog.published-page.move-error.single-conflicting-page-name.description',
		defaultMessage: `<strong>{conflictingPageName}</strong> already exists in the {destinationSpace} space. Rename your content before moving.`,
		description:
			"The error description when a user can't move their content group to the selected space because the space already contains a single content with the same name",
	},
	singleConflictingPageNameWithinEditorErrorCallToAction: {
		id: 'page-tree.move-page-dialog.published-page.move-error.single-conflicting-page-name-within-editor.call-to-action',
		defaultMessage: `Rename this page`,
		description:
			"Text that will be in a button, that when clicked will redirect the user back to the editor to rename their page because the space they are trying to move content to already contains a single page with their page's name",
	},
	singleConflictingPageNameWithinEditorErrorTitle: {
		id: 'page-tree.move-page-dialog.published-page.move-error.single-conflicting-page-name-within-editor.title',
		defaultMessage: 'Rename this page to make it unique',
		description:
			"The error title when a user can't move their page group to the selected space because the space already contains a single page with their page's name",
	},
	singleConflictingPageNameWithinEditorErrorDescription: {
		id: 'page-tree.move-page-dialog.published-page.move-error.single-conflicting-page-name-within-editor.description',
		defaultMessage: `A page titled <strong>{conflictingPageName}</strong> already exists in the {destinationSpace} space. To move this page, you’ll first need to give it a unique title and publish it.`,
		description:
			"The error description when a user can't move their page group to the selected space because the space already contains a single page with their page's name",
	},
	singleConflictingLivePageNameWithinEditorErrorDescription: {
		id: 'page-tree.move-page-dialog.live-page.move-error.single-conflicting-page-name-within-editor.description',
		defaultMessage: `There’s already a page named <strong>{conflictingPageName}</strong> in {destinationSpace}. Give this page a unique title to move it.`,
		description:
			"The error description when a user can't move their page group to the selected space because the space already contains a single page with their page's name",
	},
	multipleConflictingContentNamesErrorTitle: {
		id: 'page-tree.move-page-dialog.published-page.move-error.multiple-conflicting-page-name.title',
		defaultMessage: 'Content with these names already exist',
		description:
			"The error title when a user can't move their page group to the selected space because the space already contains multiple pages with their page's name",
	},
	multipleConflictingContentNamesErrorDescription: {
		id: 'page-tree.move-page-dialog.published-page.move-error.multiple-conflicting-page-name.description',
		defaultMessage:
			'The following content already exists in the {destinationSpace} space: {listOfConflictingNames}. Rename your content before moving.',
		description:
			"The error description when a user can't move their page group to the selected space because the space already contains multiple pages with their page's name",
	},
	restrictionsPermissionErrorTitle: {
		id: 'page-tree.move-page-dialog.published-page.move-error.no-restrictions-permissions.title',
		defaultMessage: 'You can’t move restricted content to this space',
		description:
			"The error title when a user can't move their content to the selected space because their content has restrictions and they do not have permissions " +
			'to set restrictions in the selected space',
	},
	restrictionsPermissionErrorDescription: {
		id: 'page-tree.move-page-dialog.published-page.move-error.no-restrictions-permissions.description',
		defaultMessage:
			'This content is restricted, and you don’t have permission to create restricted content in the new space. Remove restrictions before moving it.',
		description:
			"The error description when a user can't move their page to the selected space because their page has restrictions and they do not have permissions " +
			'to set restrictions in the selected space',
	},
	destinationDoesNotExistErrorTitle: {
		id: 'page-tree.move-page-dialog.published-page.move-error.destination-does-not-exist.title',
		defaultMessage: 'Select a different destination',
		description:
			'The error title when the user selects a move destination that was deleted or archived after the user opened the move dialog.',
	},
	destinationDoesNotExistErrorDescription: {
		id: 'page-tree.move-page-dialog.published-page.move-error.destination-does-not-exist.description',
		defaultMessage:
			'This destination has recently been deleted or archived. Please select a different destination.',
		description:
			'The error description when the user selects a move destination that was deleted or archived after the user opened the move dialog.',
	},
	sourceDoesNotExistErrorTitle: {
		id: 'page-tree.move-page-dialog.published-page.move-error.source-does-not-exist.title',
		defaultMessage: 'This content can’t be moved',
		description:
			'The error title when the user selects a source content to move that was deleted or archived after the user opened the move dialog.',
	},
	sourceDoesNotExistErrorDescription: {
		id: 'page-tree.move-page-dialog.published-page.move-error.source-does-not-exist.description',
		defaultMessage:
			'This content has recently been deleted or archived and therefore cannot be moved.',
		description:
			'The error description when the user selects a source page to move that was deleted or archived after the user opened the move dialog.',
	},
	defaultErrorTitle: {
		id: 'page-tree.move-page-dialog.published-page.move-error.default.title',
		defaultMessage: 'We’re having issues moving this content',
		description:
			"General error title when a user can't move their content to the selected location",
	},
	defaultErrorDescription: {
		id: 'page-tree.move-page-dialog.published-page.move-error.default.description',
		defaultMessage:
			'We’re having issues moving this content. Refresh and try again; if that doesn’t solve the issue, please contact support.',
		description:
			"General error description when a user can't move their content to the selected location",
	},
	tooManyPagesErrorTitle: {
		id: 'page-tree.move-page-dialog.published-page.move-error.too-many-pages.title',
		defaultMessage: 'We couldn’t move this many pages at once',
		description:
			'The error title when the user is trying to move more pages than the server can handle',
	},
	tooManyPagesErrorDescription: {
		id: 'page-tree.move-page-dialog.published-page.move-error.too-many-pages.description',
		defaultMessage: 'Try again by selecting a page with fewer children.',
		description:
			'The error description when the user is trying to move more pages than the server can handle',
	},
	moreThan100PagesErrorTitle: {
		id: 'page-tree.move-page-dialog.published-page.move-error.more-than-hundred-pages.title',
		defaultMessage: 'We couldn’t move this many pages at once',
		description:
			'The error title when the user is trying to move more pages than the server can handle',
	},
	moreThan100PagesErrorDescription: {
		id: 'page-tree.move-page-dialog.published-page.move-error.more-than-hundred-pages.description',
		defaultMessage: 'Try again by selecting a page with fewer than 100 children.',
		description:
			'The error description when the user is trying to move more pages than the server can handle',
	},
	movingContentToSelfErrorTitle: {
		id: 'page-tree.move-page-dialog.published-page.move-error.source-same-as-target.title',
		defaultMessage: 'You can’t move any content to a position relative to itself',
		description:
			'Specific move error title where the target of any move operation is the same as source',
	},
	movingContentToSelfErrorDescription: {
		id: 'page-tree.move-page-dialog.published-page.move-error.source-same-as-target.description',
		defaultMessage:
			'Uh, oh! You are attempting to move content to itself. Please choose other content you wish to move to.',
		description:
			'Specific move error description where the target of any move operation is the same as source',
	},
	currentlyUpdatedErrorTitle: {
		id: 'page-tree.move-page-dialog.published-page.move-error.currently-updated.title',
		defaultMessage: 'We can’t move this content right now',
		description:
			"Title of the error message shown when the content can't be moved because someone else updated the content at the exact same instant the user tried to move the content",
	},
	currentlyUpdatedErrorDescription: {
		id: 'page-tree.move-page-dialog.published-page.move-error.currently-updated.description',
		defaultMessage: 'Someone just updated this content. Refresh and try moving it again.',
		description:
			"Error message shown when the content can't be moved because someone else updated the content at the exact same instant the user tried to move the content",
	},
	partiallyMovedErrorTitle: {
		id: 'page-tree.move-page-dialog.published-page.move-error.partially-moved.title',
		defaultMessage: 'The move could not be fully completed',
		description:
			'Title of the error message shown when some of the content failed to be moved due to an error and was not able to be rolled back to the original space',
	},
	partiallyMovedErrorDescription: {
		id: 'page-tree.move-page-dialog.published-page.move-error.partially-moved.description',
		defaultMessage:
			'Some of the content was not able to be moved to the target space. If you have trouble finding any content, please reach out to Support for help.',
		description:
			'Error message shown when some of the content failed to be moved due to an error and was not able to be rolled back to the original space',
	},
	notSpaceAdminForCrossSpaceMoveTitle: {
		id: 'page-tree.move-page-dialog.published-page.move-error.not-space-admin-cross-space.title',
		defaultMessage: 'You can’t move this content',
		description:
			'Error message shown when content can’t be moved to another space due to insufficient permissions. Only space administrators can move content to another space',
	},
	notSpaceAdminForCrossSpaceMoveDescription: {
		id: 'page-tree.move-page-dialog.published-page.move-error.not-space-admin-cross-space.description',
		defaultMessage:
			'Only space admins can move content to another space. Contact your admin for help.',
		description:
			'Error message shown when content can’t be moved to another space due to insufficient permissions. Only space administrators can move content to another space',
	},
});
